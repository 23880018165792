export const gridStyle =
    'grid grid-cols-1 gap-2 items-center sm:grid-cols-3 sm:gap-4';

export const titleStyle = 'text-2xl font-semibold text-center leading-10 py-6';

export const labelStyles =
    'bg-primaryDark grid grid-cols-4 gap-7 px-5 py-3 text-white lg:px-10 xl:px-20';

export const inputStyles =
    'grid grid-cols-4 gap-7 px-5 py-6 items-center lg:px-10 xl:px-20';
