import { useFormContext } from 'react-hook-form';
import { FormInputs } from '../../types';
import { InputField, InputLable } from '../InputField';
import { inputStyles, labelStyles } from './BookingForm.styles';

const BrideForm = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext<FormInputs>();

    return (
        <div className="hidden lg:flex lg:flex-col lg:gap-6">
            <div className="border border-border">
                <div className={labelStyles}>
                    <InputLable label="Name" id="bride-name" />
                    <InputLable label="Date of Birth" id="dob" />
                    <InputLable label="Aadhar No" id="aadhar" />
                    <InputLable label="Upload Aadhar Card" id="aadhar-file" />
                </div>
                <div className={inputStyles}>
                    <InputField
                        id="bride-name"
                        {...register('brideDetails.name', {
                            required: true,
                        })}
                        hasError={!!errors.brideDetails?.name}
                    />
                    <InputField
                        id="dob"
                        type="date"
                        {...register('brideDetails.dob', {
                            required: true,
                        })}
                        hasError={!!errors.brideDetails?.dob}
                    />
                    <InputField
                        id="aadhar"
                        {...register('brideDetails.aadharNo', {
                            required: true,
                            minLength: 12,
                            maxLength: 12,
                        })}
                        hasError={!!errors.brideDetails?.aadharNo}
                    />
                    <input
                        className="block w-full cursor-pointer"
                        id="aadhar-file"
                        type="file"
                    />
                </div>
            </div>
            {/* Bride Father */}
            <div className="border border-border">
                <div className={labelStyles}>
                    <InputLable label="Father Name" id="bride-father-name" />
                    <InputLable label="Date of Birth" id="bride-father-dob" />
                    <InputLable label="Aadhar No" id="bride-father-aadhar" />
                    <InputLable
                        label="Upload Aadhar Card"
                        id="bride-father-aadhar-file"
                    />
                </div>
                <div className={inputStyles}>
                    <InputField
                        {...register('fatherDetails.name', {
                            required: true,
                        })}
                        hasError={!!errors.fatherDetails?.name}
                        id="bride-father-name"
                    />
                    <InputField
                        {...register('fatherDetails.dob', {
                            required: true,
                        })}
                        hasError={!!errors.fatherDetails?.name}
                        id="bride-father-dob"
                        type="date"
                    />
                    <InputField
                        {...register('fatherDetails.aadharNo', {
                            required: true,
                        })}
                        hasError={!!errors.fatherDetails?.name}
                        id="bride-mother-aadhar"
                    />
                    <input
                        className="block w-full cursor-pointer"
                        id="bride-mother-aadhar-file"
                        type="file"
                    />
                </div>
            </div>
            {/* Bride Mother */}
            <div className="border border-border">
                <div className={labelStyles}>
                    <InputLable label="Mother Name" id="bride-mother-name" />
                    <InputLable label="Date of Birth" id="bride-mother-dob" />
                    <InputLable label="Aadhar No" id="bride-mother-aadhar" />
                    <InputLable
                        label="Upload Aadhar Card"
                        id="bride-mother-aadhar-file"
                    />
                </div>
                <div className={inputStyles}>
                    <InputField
                        {...register('motherDetails.name', {
                            required: true,
                        })}
                        hasError={!!errors.motherDetails?.name}
                        id="bride-mother-name"
                    />
                    <InputField
                        {...register('motherDetails.dob', {
                            required: true,
                        })}
                        hasError={!!errors.motherDetails?.dob}
                        id="bride-mother-dob"
                        type="date"
                    />
                    <InputField
                        {...register('motherDetails.aadharNo', {
                            required: true,
                        })}
                        hasError={!!errors.motherDetails?.dob}
                        id="bride-mother-aadhar"
                    />
                    <input
                        className="block w-full cursor-pointer"
                        id="bride-mother-aadhar-file"
                        type="file"
                    />
                </div>
            </div>
            <div className="border border-border">
                <div className="bg-primaryDark grid grid-cols-3 gap-7 px-5 py-3 text-white lg:px-10 xl:px-20">
                    <InputLable label="Mobile Number" id="bride-mobile" />
                    <InputLable label="Address" id="bride-address" />
                    <InputLable
                        label="Upload Invitation card"
                        id="bride-invitation-file"
                    />
                </div>
                <div className="grid grid-cols-3 gap-7 px-5 py-6 items-center lg:px-10 xl:px-20">
                    <InputField
                        {...register('mobileNo', {
                            required: true,
                        })}
                        hasError={!!errors.mobileNo}
                        id="bride-mobile"
                        type="number"
                    />
                    <InputField
                        {...register('address', {
                            required: true,
                        })}
                        hasError={!!errors.address}
                        id="bride-address"
                    />
                    <input
                        className="block w-full cursor-pointer"
                        id="bride-invitation-file"
                        type="file"
                    />
                </div>
            </div>
        </div>
    );
};
export default BrideForm;
