import { useFormContext } from 'react-hook-form';
import { FormInputs } from '../../types';
import { InputField, InputLable } from '../InputField';
import { gridStyle } from './BookingForm.styles';

const GroomMobileForm = () => {
    const {
        register,
        formState: { errors },
    } = useFormContext<FormInputs>();

    return (
        <div className="flex flex-col gap-6 lg:hidden">
            <div className={gridStyle}>
                <InputLable label="Name" id="groom-name" />
                <div className="col-span-2">
                    <InputField
                        id="groom-name"
                        {...register('groomDetails.name', {
                            required: true,
                        })}
                        hasError={!!errors.groomDetails?.name}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable label="Date of Birth" id="dob" />
                <div className="col-span-2">
                    <InputField
                        id="dob"
                        type="date"
                        {...register('groomDetails.dob', {
                            required: true,
                        })}
                        hasError={!!errors.groomDetails?.dob}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable label="Aadhar No" id="aadhar" />
                <div className="col-span-2">
                    <InputField
                        id="aadhar"
                        {...register('groomDetails.aadharNo', {
                            required: true,
                        })}
                        hasError={!!errors.groomDetails?.aadharNo}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable label="Upload Aadhar Card" id="aadhar-file" />
                <div className="col-span-2">
                    <input id="aadhar-file" type="file" />
                </div>
            </div>
            {/* Father Details */}
            <div className={gridStyle}>
                <InputLable label="Father Name" id="groom-father-name" />
                <div className="col-span-2">
                    <InputField
                        id="groom-father-name"
                        {...register('fatherDetails.name', {
                            required: true,
                        })}
                        hasError={!!errors.fatherDetails?.name}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable label="Date of Birth" id="groom-father-dob" />
                <div className="col-span-2">
                    <InputField
                        id="groom-father-dob"
                        type="date"
                        {...register('fatherDetails.dob', {
                            required: true,
                        })}
                        hasError={!!errors.fatherDetails?.dob}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable label="Aadhar No" id="groom-father-aadhar" />
                <div className="col-span-2">
                    <InputField
                        id="groom-father-aadhar"
                        {...register('fatherDetails.aadharNo', {
                            required: true,
                        })}
                        hasError={!!errors.fatherDetails?.aadharNo}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable
                    label="Upload Aadhar Card"
                    id="groom-father-aadhar-file"
                />
                <div className="col-span-2">
                    <input id="groom-father-aadhar-file" type="file" />
                </div>
            </div>
            {/* Mother Details */}
            <div className={gridStyle}>
                <InputLable label="Mother Name" id="groom-mother-name" />
                <div className="col-span-2">
                    <InputField
                        id="groom-mother-name"
                        {...register('motherDetails.name', {
                            required: true,
                        })}
                        hasError={!!errors.motherDetails?.name}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable label="Date of Birth" id="groom-mother-dob" />
                <div className="col-span-2">
                    <InputField
                        id="groom-mother-dob"
                        type="date"
                        {...register('motherDetails.dob', {
                            required: true,
                        })}
                        hasError={!!errors.motherDetails?.dob}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable label="Aadhar No" id="groom-mother-aadhar" />
                <div className="col-span-2">
                    <InputField
                        id="groom-mother-aadhar"
                        {...register('motherDetails.aadharNo', {
                            required: true,
                        })}
                        hasError={!!errors.motherDetails?.aadharNo}
                    />
                </div>
            </div>
            <div className={gridStyle}>
                <InputLable
                    label="Upload Aadhar Card"
                    id="groom-mother-aadhar-file"
                />
                <div className="col-span-2">
                    <input id="groom-mother-aadhar-file" type="file" />
                </div>
            </div>
        </div>
    );
};

export default GroomMobileForm;
