import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import BookingForm from '../components/BookingForm/BookingForm';

type FormInputs = {
    bride: FormInputs;
    groom: FormInputs;
};

const BookingFormPage = () => {
    const methods = useForm<FormInputs>();

    const onSubmit: SubmitHandler<FormInputs> = (data) => console.log(data);

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="bg-[#F0F2ED]">
                    <div className="px-4 mx-auto pt-10 container">
                        <BookingForm />
                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="text-white bg-primaryDark font-bold text-2xl px-10 py-2 sm:text-lg my-14"
                                onClick={() => {
                                    // TODO: Add API call to save data
                                }}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </FormProvider>
    );
};

export default BookingFormPage;
