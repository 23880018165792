const SocialInfo = () => {
    const facebookIcon = require('../../assets/facebook.svg').default;
    const instagramIcon = require('../../assets/instagram.svg').default;
    const twitterIcon = require('../../assets/twitter.svg').default;
    const mailIcon = require('../../assets/mail.svg').default;
    const appstoreIcon = require('../../assets/appstore.svg').default;
    const playstoreIcon = require('../../assets/playstore.svg').default;
    const arrowRight = require('../../assets/arrow-right.svg').default;

    return (
        <div>
            <div className="flex gap-4">
                <a
                    href="https://www.facebook.com/annavaramdevasthanam/" // TODO: Add Facebook link
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={facebookIcon}
                        alt="facebook"
                        className="h-8 w-8"
                    />
                </a>
                <a
                    href="https://www.instagram.com/annavaramdevasthanam/" // TODO: Add Instagram link
                    target="_blank"
                    rel="noreferrer"
                >
                    <img
                        src={instagramIcon}
                        alt="instagram"
                        className="h-8 w-8"
                    />
                </a>
                <a
                    href="https://twitter.com/annavaramdevasthanam" // TODO: Add Twitter link
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={twitterIcon} alt="twitter" className="h-8 w-8" />
                </a>
            </div>
            <div className="text-white text-xl font-medium my-4">
                Subscribe Newsletter
            </div>
            <div className="relative max-w-sm text-gray-600 focus-within:text-gray-400">
                <span className="absolute inset-y-0 left-0 flex items-center pl-4">
                    <img src={mailIcon} className="w-7" alt="email" />
                </span>
                <input
                    type="email"
                    className="px-12 rounded-lg p-3 w-full"
                    placeholder="Email Address"
                />
                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                    <img src={arrowRight} className="w-7" alt="email" />
                </span>
            </div>
            <div className="text-white text-xl font-medium my-4">
                Download Mobile App
            </div>
            <div className="flex gap-4">
                <a
                    href="https://play.google.com/store/apps" // TODO: add google play store link
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={playstoreIcon} alt="playstore" />
                </a>
                <a
                    href="https://play.google.com/store/apps" // TODO: add apple store link
                    target="_blank"
                    rel="noreferrer"
                >
                    <img src={appstoreIcon} alt="appstore" />
                </a>
            </div>
        </div>
    );
};

export default SocialInfo;
