import { NavLink } from 'react-router-dom';
import Select from '../components/Select';

const BookingPage = () => {
    return (
        <div className="bg-[#F0F2ED]">
            <div className="container px-4 mx-auto">
                <h1 className="text-2xl font-medium text-center leading-10 py-10 lg:text-4xl">
                    Kalyanamandapam Booking
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <div>
                        <label
                            htmlFor="booking-date"
                            className="block text-xl font-medium leading-10 mb-2"
                        >
                            <span className="text-red">*</span>
                            Booking Date
                        </label>
                        <input
                            className="bg-white border-border border-2 block w-full px-4 py-2 dark:placeholder-gray-400
                                        text-xl font-medium opacity-70"
                            type="date"
                        />
                    </div>
                    <Select
                        id="kalyanamandam-type"
                        label="Kalyanamandam Type"
                        options={['A/C', 'Non A/C']}
                        required
                    />
                    <Select
                        id="slot"
                        label="Slot"
                        options={['Morning', 'Evening']}
                        required
                    />
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-8 py-11">
                    {Array.from({ length: 20 }).map((_, index) => (
                        <NavLink key={index} to={`/booking/${index}`}>
                            <img
                                src="https://images.unsplash.com/photo-1524443169398-9aa1ceab67d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500"
                                alt="Kalyanamandapam"
                                className="rounded-md transform transition duration-300 hover:scale-105"
                            />
                            <div className="text-xl font-medium leading-10 text-center">
                                Kalyanamandapam {index + 1}
                            </div>
                        </NavLink>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default BookingPage;
