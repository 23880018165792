import { forwardRef, InputHTMLAttributes } from 'react';

export interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    hasError?: boolean;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
    ({ hasError, ...props }, ref) => (
        <div>
            <input
                className="bg-white border-border border block w-full px-4 py-2
            text-xl font-medium"
                ref={ref}
                {...props}
            />
            {hasError && (
                <span className="absolute text-red text-sm font-medium">
                    This field is required
                </span>
            )}
        </div>
    )
);

export const InputLable = ({ label, id }: { label: string; id: string }) => {
    return (
        <label htmlFor={id} className="block text-xl font-medium">
            <span className="text-red">* </span>
            {label}
        </label>
    );
};
