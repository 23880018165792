import DataWithIcon from '../DataWithIcon';

const ContactInfo = () => {
    const locationIcon = require('../../assets/location.svg').default;
    const globeIcon = require('../../assets/globe.svg').default;
    const phoneIcon = require('../../assets/phone.svg').default;
    const printerIcon = require('../../assets/printer.svg').default;

    return (
        <div className="py-4 flex flex-col gap-6">
            <DataWithIcon
                icon={locationIcon}
                children={
                    <div>
                        Sri Veera Venkata Satyanarayana Swamy Vari Devasthanam,
                        <br /> Annavaram – 533 406,
                        <br />
                        East Godavari District,
                        <br />
                        Andhra Pradesh.
                    </div>
                }
            />
            <DataWithIcon
                icon={globeIcon}
                children="www.annavaramdevasthanam.org"
            />
            <DataWithIcon
                icon={phoneIcon}
                children="9491249990, 08868-238121,238125 & 238163"
            />
            <DataWithIcon icon={printerIcon} children="08868-238124" />
        </div>
    );
};

export default ContactInfo;
