import Logo from '../Logo';
import ContactInfo from './_ContactInfo';
import FooterLinks from './_FooterLinks';
import SocialInfo from './_SocialInfo';

const Footer = () => {
    return (
        <>
            <footer className="bg-primary bg-footer-bg bg-no-repeat bg-bottom px-5 pt-10 lg:px-24 pb-64 lg:pt-20">
                <Logo />
                <div className="flex justify-between flex-col gap-7 pb-10 lg:flex-row lg:pb-0">
                    <ContactInfo />
                    <SocialInfo />
                </div>
            </footer>
            <FooterLinks />
        </>
    );
};

export default Footer;
