import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface UIState {
    paused: boolean;
}

const initialState: UIState = {
    paused: false,
};

export const uiSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        setPause: (state, action: PayloadAction<UIState['paused']>) => {
            state.paused = action.payload;
        },
    },
});

export const { setPause } = uiSlice.actions;

export default uiSlice.reducer;
