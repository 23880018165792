const footerLinksData = [
    {
        name: 'Privacy Policy',
        url: 'https://www.apendownments.com/privacy-policy/',
    },
    {
        name: 'Refund Policy',
        url: 'https://www.apendownments.com/privacy-policy/',
    },
    {
        name: 'Trademarks',
        url: 'https://www.apendownments.com/privacy-policy/',
    },
    {
        name: 'Terms & conditions',
        url: 'https://www.apendownments.com/privacy-policy/',
    },
    {
        name: 'Cautionary Notice',
        url: 'https://www.apendownments.com/privacy-policy/',
    },
];

const FooterLinks = () => {
    return (
        <footer className="bg-secondaryDark p-5 lg:px-24">
            <ul className="flex flex-wrap gap-4">
                <li className="text-white font-medium">
                    ©️ 2022 A P Endownments Department. All rights reserved.
                </li>
                {footerLinksData.map((item) => (
                    <li key={item.name} className="text-[#849AAF]">
                        <a href={item.url}>{item.name}</a>
                    </li>
                ))}
            </ul>
        </footer>
    );
};

export default FooterLinks;
