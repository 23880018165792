import { configureStore } from '@reduxjs/toolkit';
import dataReducer from './data.store';
import uiReducer from './ui.store';

export const store = configureStore({
    reducer: {
        data: dataReducer,
        ui: uiReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
