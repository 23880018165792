const AboutTemple = () => {
    const aboutTempleImage = require('../../assets/about.png');

    const Paragraph1 = () => (
        <p className="my-6 text-justify text-lg font-medium leading-8 indent-16 capitalize lg:text-xl lg:leading-10">
            Annavaram is one of the famous Holy Shrines in Andhra Pradesh. The
            Temple is built in the Dravidian Style. The glory and richness of
            lord satyadeva was widely described in Revankhanda of Skandapuranam.
            The presiding deity Lord Satyadeva with his consort Sri Anantha
            Lakshmi on one side and with Lord Siva on other side like in all
            Divayakshetrams as surrounded and followed by holy rivers, Sri
            Satyanarayana Swamy at Annavaram is also followed by sacred Pamapa
            River touching in the feet of the hill.
        </p>
    );

    const Paragraph2 = () => (
        <p className="text-justify text-lg font-medium leading-8 indent-16 capitalize lg:text-xl lg:leading-10">
            Lord Satyadeva the god of truth is showing his eternal blessings on
            mankind in his beautiful manifested form of the Divine Trinity
            namely Hari Hara Hiranya Garbha Thrimurtyatmaka. Hence, this is the
            temple that is attracting the Pilgrims of both Vaishnava and Saiva
            devotees and thousands of pilgrims from all over the Country are
            worshiping Lord Satyadeva everyday forgetting all sectional
            differences.
        </p>
    );

    return (
        <section className="mx-auto flex flex-col items-center px-5 py-16 lg:flex-row lg:mx-24">
            <h2 className="capitalize text-textPrimary text-3xl font-bold text-center leading-10 pb-8 lg:hidden">
                About annavaram temple
            </h2>
            <img
                src={aboutTempleImage}
                alt="about-logo"
                className="sm:w-2/3 md:w-1/2 lg:w-2/6"
            />
            <div className="lg:ml-14 lg:w-3/4">
                <h2 className="hidden capitalize text-textPrimary text-3xl font-bold text-center leading-10 pb-8 lg:block">
                    About annavaram temple
                </h2>
                <Paragraph1 />
                <Paragraph2 />
                <div className="w-full flex justify-end mt-8">
                    <button
                        type="button"
                        className="text-white bg-yellow font-semibold 
                                 rounded-lg text-xl px-5 py-2.5 mr-2 mb-2
                                 sm:text-2xl"
                    >
                        Read More
                    </button>
                </div>
            </div>
        </section>
    );
};

export default AboutTemple;
