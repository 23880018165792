import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Mandapam {
    items: Item[];
    meta: Meta;
}

export interface Item {
    id: number;
    name: string;
    mandapamNumber: string;
    imageUrl: string;
    type: string;
    enabled: boolean;
    userId: number;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
}

export interface Slots {
    items: Slot[];
    meta: Meta;
}

export interface Slot {
    id: number;
    annavaramKalyanaMandapamId: number;
    name: string;
    startTime: string;
    endTime: string;
    enabled: boolean;
    userId: number;
    createdAt: Date;
    updatedAt: Date;
    deletedAt: Date | null;
}

export interface Meta {
    currentPage: number;
    itemCount: number;
    itemsPerPage: number;
    totalItems: number;
    totalPages: number;
}

export interface DataState {
    mandapams: Item[];
    slots: Slot[];
    mandapamModal: {
        open: boolean;
        data?: Item;
    };
    slotModal: {
        open: boolean;
        data?: Slot;
    };
}

const initialState: DataState = {
    mandapams: [],
    slots: [],
    mandapamModal: {
        open: false,
    },
    slotModal: {
        open: false,
    },
};

export const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {
        updateMandapams: (
            state,
            action: PayloadAction<DataState['mandapams']>
        ) => {
            state.mandapams = action.payload.sort((a, b) => a.id - b.id);
        },
        setMandapamModal: (
            state,
            action: PayloadAction<DataState['mandapamModal']>
        ) => {
            state.mandapamModal = action.payload;
        },
        updateSlots: (state, action: PayloadAction<DataState['slots']>) => {
            state.slots = action.payload.sort((a, b) => a.id - b.id);
        },
        setSlotModal: (
            state,
            action: PayloadAction<DataState['slotModal']>
        ) => {
            state.slotModal = action.payload;
        },
    },
});

export const { updateMandapams, setMandapamModal, updateSlots, setSlotModal } =
    dataSlice.actions;

export default dataSlice.reducer;
