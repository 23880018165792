import { FC, ReactNode } from 'react';

interface DataWithIconProps {
    icon: string;
    children: ReactNode;
}

const DataWithIcon: FC<DataWithIconProps> = ({ icon, children }) => {
    return (
        <div className="flex flex-auto">
            <img src={icon} alt="icon" className="h-8 w-8" />
            <div className="ml-4 text-white font-medium">{children}</div>
        </div>
    );
};
export default DataWithIcon;
