import { useNavigate } from 'react-router-dom';

const HeroSection = () => {
    const navigate = useNavigate();

    return (
        <div className="max-w-full min-h-screen bg-hero-bg bg-cover bg-no-repeat">
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 text-center">
                <h1 className="text-white text-4xl font-bold mb-8 md:text-6xl lg:text-8xl lg:mb-14">
                    Kalyanamandapam
                </h1>
                <button
                    type="button"
                    className="text-white bg-yellow font-semibold 
                                 rounded-lg text-xl px-5 py-2.5 mr-2 mb-2
                                 sm:text-2xl"
                    onClick={() => navigate('/booking')}
                >
                    Book Now
                </button>
            </div>
        </div>
    );
};

export default HeroSection;
