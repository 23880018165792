import { NavLink } from 'react-router-dom';

const Logo = () => {
    const logo = require('../../assets/logo.png');

    return (
        <NavLink to="/">
            <div className="flex items-center">
                <img src={logo} alt="logo" className="h-20 sm:h-28" />
                <h3
                    className="text-white font-semibold max-w-xs ml-2
                    sm:font-black sm:text-xl sm:ml-3"
                >
                    Sri Veera Venkata
                    <br />
                    Satyanarayana Swamivari
                    <br />
                    Devasthanam
                </h3>
            </div>
        </NavLink>
    );
};

export default Logo;
