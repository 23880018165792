import React from 'react';
// import { NavLink } from 'react-router-dom';
import Logo from '../Logo';

const Header = () => {
    // const [navbarOpen, setNavbarOpen] = React.useState(false);
    return (
        <header className="bg-primary py-3">
            <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
                <div className="w-full relative flex justify-between items-center lg:w-auto lg:static lg:block lg:justify-start">
                    <Logo />
                    {/* <button
                        className="h-min text-white cursor-pointer text-xl px-3 py-1 block lg:hidden"
                        type="button"
                        onClick={() => setNavbarOpen(!navbarOpen)}
                    >
                        {!navbarOpen ? (
                            <svg className="h-6 w-6" viewBox="0 0 20 20">
                                <title>Menu</title>
                                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                            </svg>
                        ) : (
                            <svg className="h-6 w-6" viewBox="0 0 20 20">
                                <title>Close</title>
                                <path d="M14.95 6.46L11.41 10l3.54 3.54-1.41 1.41L10 11.41l-3.54 3.54-1.41-1.41L8.59 10 5.05 6.46l1.41-1.41L10 8.59l3.54-3.54z" />
                            </svg>
                        )}
                    </button> */}
                </div>
                {/* <div
                    className={
                        'lg:flex flex-grow items-center' +
                        (navbarOpen ? ' flex' : ' hidden')
                    }
                    id="nav-bar"
                >
                    <ul className="flex flex-col gap-4 text-white text-2xl font-medium py-4 lg:flex-row list-none lg:ml-auto">
                        <li className="nav-item">
                            <NavLink to="/">
                                <div
                                    className={
                                        'opacity-70' +
                                        (window.location.pathname === '/'
                                            ? ' font-black border-b-4 border-white opacity-100'
                                            : '')
                                    }
                                >
                                    Home
                                </div>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink to="/booking">
                                <div
                                    className={
                                        'opacity-70' +
                                        (window.location.pathname === '/booking'
                                            ? ' font-black border-b-4 border-white opacity-100'
                                            : '')
                                    }
                                >
                                    Kalyanamandapam Booking
                                </div>
                            </NavLink>
                        </li>
                    </ul>
                </div> */}
            </div>
        </header>
    );
};

export default Header;
