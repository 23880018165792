const imagesList = [
    'https://images.unsplash.com/photo-1603766806347-54cdf3745953?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500',
    'https://images.unsplash.com/photo-1569571665379-f952b753ccc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500',
    'https://images.unsplash.com/photo-1584271854089-9bb3e5168e32?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500',
    'https://images.unsplash.com/photo-1564933356-a0c8922d8926?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500',
    'https://images.unsplash.com/photo-1588008337614-107a827d031b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500',
    'https://images.unsplash.com/photo-1601220840366-d29aedc7e987?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500',
    'https://images.unsplash.com/photo-1569571665379-f952b753ccc7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500',
    'https://images.unsplash.com/photo-1584271854089-9bb3e5168e32?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500',
];

const PhotoGallery = () => {
    return (
        <section className="container px-5 lg:mx-24">
            <h3 className="text-textPrimary text-3xl font-bold text-center leading-10">
                Photo Gallery
            </h3>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-9 pb-12">
                {imagesList.map((image, index) => (
                    <div key={index}>
                        <img
                            className="h-full w-full object-cover rounded transform transition duration-300 hover:scale-105"
                            src={image}
                            alt={`Kalyanamandapam ${index + 1}`}
                        />
                    </div>
                ))}
            </div>
        </section>
    );
};

export default PhotoGallery;
