import { titleStyle } from './BookingForm.styles';
import BrideForm from './BrideForm';
import BrideMobileForm from './BrideMobileForm';
import GroomForm from './GroomForm';
import GroomMobileForm from './GroomMobileForm';

const BookingForm = () => {
    return (
        <>
            <h3 className={titleStyle}>Bride Details</h3>
            <BrideMobileForm />
            <BrideForm />
            <h3 className={titleStyle}>Groom Details</h3>
            <GroomMobileForm />
            <GroomForm />
        </>
    );
};

export default BookingForm;
