import { lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import BookingFormPage from './views/BookingFormPage';
import BookingPage from './views/BookingPage';
import HomePage from './views/HomePage';
import MainPage from './views/MainPage';

const AdminAppLayout = lazy(() => import('./admin/views/AdminAppLayout'));
const AdminPage = lazy(() => import('./admin/views/AdminPage'));
const ManagementPage = lazy(() => import('./admin/views/ManagementPage'));
const SlotsPage = lazy(() => import('./admin/views/SlotsPage'));
const ReportsPage = lazy(() => import('./admin/views/ReportsPage'));
const AuthPage = lazy(() => import('./admin/views/Auth/AuthPage'));
const Login = lazy(() => import('./admin/views/Auth/Login'));
const CreatePassword = lazy(() => import('./admin/views/Auth/CreatePassword'));
const ResetPassword = lazy(() => import('./admin/views/Auth/ResetPassword'));

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<MainPage />}>
                    <Route path="/" element={<HomePage />} />
                    <Route path="booking" element={<BookingPage />} />
                    <Route path="booking/:id" element={<BookingFormPage />} />
                </Route>
                <Route path="/admin" element={<AdminAppLayout />}>
                    <Route path="admin" element={<AdminPage />} />
                    <Route path="management" element={<ManagementPage />} />
                    <Route
                        path="management/slots/:id"
                        element={<SlotsPage />}
                    />
                    <Route path="reports" element={<ReportsPage />} />
                </Route>
                <Route path="/admin/login" element={<AuthPage />}>
                    <Route path="/admin/login" element={<Login />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                    <Route
                        path="create-password"
                        element={<CreatePassword />}
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
