import AboutTemple from '../components/AboutTemple';
import HeroSection from '../components/HeroSection';
import PhotoGallery from '../components/PhotoGallery';

const HomePage = () => {
    return (
        <>
            <HeroSection />
            <AboutTemple />
            <PhotoGallery />
        </>
    );
};

export default HomePage;
