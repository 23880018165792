import React, { FC, SelectHTMLAttributes } from 'react';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
    label: string;
    options: string[];
    required?: boolean;
    selectedOption?: string;
}

const Select: FC<SelectProps> = ({
    label,
    options,
    required,
    selectedOption,
    ...others
}) => {
    return (
        <div>
            <label
                htmlFor={others.id}
                className="block text-xl font-medium leading-10 mb-2"
            >
                {required && <span className="text-red">*</span>}
                {label}
            </label>
            <select
                className="bg-white border-border border-2 block w-full px-4 py-2 dark:placeholder-gray-400
                text-xl font-medium opacity-70"
                {...others}
            >
                <option defaultValue={selectedOption}>Select {label}</option>
                {options.map((option) => (
                    <option key={option}>{option}</option>
                ))}
            </select>
        </div>
    );
};

export default Select;
